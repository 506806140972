import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';
import { Typography, Button, Hidden, ClickAwayListener, Box } from '@material-ui/core';
import { SteerTitle } from '../../Icons';
import { MENU_ITEMS, SECTION_IDS, SERVICE_SUB_ITEMS, INDUSTRIES_SUB_ITEMS } from '../../../constants';
import { BodyText, H4 } from '../../';

interface Props {
  activePath: string;
}

const DesktopHeader = ({ activePath }: Props) => {
  const [showServiceMenu, setVisibleServiceMenu] = useState(false);
  const [showIndustryMenu, setVisibleIndustryMenu] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);
  const onClickMenuItem = (menuItem: { label: string; path: string }) => {
    navigate(menuItem.path);
  };

  const onShowMenu = (event: any, index: number) => {
    if (index === 2) {
      setVisibleServiceMenu(true);
      setVisibleIndustryMenu(false);
    } else {
      setVisibleIndustryMenu(true);
      setVisibleServiceMenu(false);
    }
  };

  const closeMenu = () => {
    setVisibleServiceMenu(false);
    setVisibleIndustryMenu(false);
    setMouseEntered(false);
  };

  const onMouseEnter = () => setMouseEntered(true);

  const checkAndHideMenu = () => {
    console.info('checkAndHideMenu');
    if (!mouseEntered) {
      closeMenu();
    }
  };

  const onClickLogo = () => navigate('/');

  console.info('mouseEntered', mouseEntered);

  return (
    <Box sx={{ display: { sm: 'none', md: 'block' } }}>
      <Fragment>
        <div className="header-container">
          <div className="header-logo">
            <SteerTitle type="web" onClick={onClickLogo} />
          </div>

          <div className="header-items header-button-container items-center" onMouseLeave={checkAndHideMenu}>
            {MENU_ITEMS.map((item, index) => (
              <Fragment key={`key-${index}`}>
                <Button
                  className="header-button"
                  onClick={() => onClickMenuItem(item)}
                  disableRipple
                  onMouseOver={(event) => (index === 2 || index === 3 ? onShowMenu(event, index) : closeMenu())}
                >
                  <Typography className={activePath !== item.path ? 'header-text' : 'header-text header-underline'}>
                    {item.label}
                  </Typography>
                </Button>
              </Fragment>
            ))}
            <ClickAwayListener onClickAway={closeMenu}>
              <aside className="service-drop-menu flex">
                <div className="service-left-padding" />
                <div className="service-main flex">
                  <div className="flex-1" />
                  <div
                    className={`flex-1 service-drop flex-column ${showServiceMenu ? 'flex' : ''}`}
                    onMouseLeave={closeMenu}
                    onMouseEnter={onMouseEnter}
                  >
                    {SERVICE_SUB_ITEMS.map((subItem, index) => (
                      <H4
                        className={activePath === subItem.path ? 'text-left menu-underline' : 'text-left'}
                        onClick={() => navigate(subItem.path)}
                        key={`service-subItem-${index}`}
                      >
                        {subItem.label}
                      </H4>
                    ))}
                  </div>
                  <div className="flex-1" />
                  <div className="flex-1" />
                  <div className="flex-1" />
                </div>
              </aside>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={closeMenu}>
              <aside className="service-drop-menu flex">
                <div className="service-left-padding" />
                <div className="service-main flex">
                  <div className="flex-1" />
                  <div className="flex-1" />
                  <div
                    className={`flex-1 industry-drop flex-column ${showIndustryMenu ? 'flex' : ''}`}
                    onMouseLeave={closeMenu}
                    onMouseEnter={onMouseEnter}
                  >
                    {INDUSTRIES_SUB_ITEMS.map((subItem, index) => (
                      <H4
                        className={activePath === subItem.path ? 'text-left menu-underline' : 'text-left'}
                        onClick={() => navigate(subItem.path)}
                        key={`industry-subItem-${index}`}
                      >
                        {subItem.label}
                      </H4>
                    ))}
                  </div>
                  <div className="flex-1" />
                  <div className="flex-1" />
                </div>
              </aside>
            </ClickAwayListener>
          </div>
        </div>
      </Fragment>
    </Box>
  );
};

export default DesktopHeader;
