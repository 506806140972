import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H2, H4, H3, FadeInSection } from '../../';
import { SECTION_IDS } from '../../../constants';
import { TransactionalSecondType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: TransactionalSecondType;
}

const SecondSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.second} className="industry-sub-second-container section-space horizontal-section-space">
      <div className="flex main-container">
        <div className="flex-1 flex">
          <FadeInSection timeout={200}>
            <RenderImage
              imgUrl={content.mainImage.asset.url}
              gatsbyImg={content.mainImage.asset.gatsbyImageData}
              style={'second-image'}
              sx={{ maxWidth: '830px' }}
            />
          </FadeInSection>
        </div>
        <div className="space" />
        <div className="flex-1 flex justify-center flex-column">
          <H3 className="primary-text">{content.title}</H3>
          <FadeInSection timeout={200}>
            <div className="block-container">
              <BlockContent blocks={content._rawDescription} />
            </div>
          </FadeInSection>
        </div>
      </div>
    </section>
  );
};

export default SecondSection;
