import React, { useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Collapse } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SECTION_IDS } from '../../../constants';
import { Button } from '@material-ui/core';

import { H1, H2, H3, H4, BodyText, FadeInSection } from '../..';
import { AboutFourthType, AvatarItemType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: AboutFourthType;
}

interface ItemProps {
  index: number;
  item: AvatarItemType;
}

const Item = ({ index, item }: ItemProps) => {
  const [showReadMore, setReadMore] = useState(false);

  const onClick = () => {
    setReadMore(!showReadMore);
  };

  return (
    <div className={`avatar-item flex-1`}>
      <RenderImage
        imgUrl={item.avatarImg.asset.url}
        gatsbyImg={item.avatarImg.asset.gatsbyImageData}
        style={'avatar-image'}
        sx={{ maxHeight: '1260px', maxWidth: '840px' }}
      />
      <div className="avatar-description-container flex justify-between items-center">
        <H3>{item.name}</H3>
        <H4>{item.role}</H4>
      </div>
      <div className="avatar-bottom-container">
        <FadeInSection timeout={300}>
          <BlockContent blocks={item._rawBio[0]} />
          <Collapse in={showReadMore}>
            <BlockContent blocks={item._rawBio.slice(1)} />
          </Collapse>
        </FadeInSection>

        <Button className="read-more-button" onClick={onClick} disableRipple>
          <div className="read-more">{showReadMore ? 'Read Less' : 'Read More'}</div>
        </Button>
      </div>
    </div>
  );
};

const FourthSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.fourth} className="about-fourth-container flex">
      <div className="main-container">
        <div className="leadership-title">
          <H2>{content.title}</H2>
        </div>
        <BodyText className="leadership-description-text">{content.description}</BodyText>
        <div className="avatar-item-container flex justify-between">
          {content.aboutAvatars.map((item, index) => (
            <Item item={item} index={index} key={`leardership-avatar-${index}`} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FourthSection;
