import React, { Fragment, useEffect } from 'react';
import { Button } from '@material-ui/core';
import BlockContent from '@sanity/block-content-to-react';
import { FadeInSection } from '../';
import { AccordionItem } from '../../utils/globalTypes';
import './index.scss';

interface Props {
  data: AccordionItem[];
}

const JobAccordion = ({ data }: Props) => {
  useEffect(() => {
    onInitial();
  }, []);

  const onInitial = () => {
    const accordionBtn = document.querySelectorAll('.accordionTitle');
    const allTexts = document.querySelectorAll('.text');
    const accIcon = document.querySelectorAll('.accIcon');
    const accStatus = document.querySelectorAll('.accStatus');

    accIcon[0].classList.add('anime');
    accStatus[0].classList.add('accStatusActive');
    allTexts[0].classList.add('show');
    accordionBtn[0].classList.add('accordionTitleActive');
  };
  const onClick = (event: any) => {
    const accordionBtn = document.querySelectorAll('.accordionTitle');
    const allTexts = document.querySelectorAll('.text');
    const accIcon = document.querySelectorAll('.accIcon');
    const accStatus = document.querySelectorAll('.accStatus');
    const targetText = event.currentTarget.nextElementSibling?.classList;
    const targetAccIcon = event.currentTarget.children[1];
    const targetAccStatus = event.currentTarget.children[0];
    const target = event.currentTarget;
    if (!targetText) return;
    if (targetText.contains('show')) {
      targetText.remove('show');
      targetAccIcon.classList.remove('anime');
      target.classList.remove('accordionTitleActive');
      targetAccStatus.classList.remove('accStatusActive');
    } else {
      accordionBtn.forEach(function (el) {
        el.classList.remove('accordionTitleActive');

        allTexts.forEach(function (el) {
          el.classList.remove('show');
        });

        accIcon.forEach(function (el) {
          el.classList.remove('anime');
        });
        accStatus.forEach(function (el) {
          el.classList.remove('accStatusActive');
        });
      });

      targetText.add('show');
      target.classList.add('accordionTitleActive');
      targetAccIcon.classList.add('anime');
      targetAccStatus.classList.add('accStatusActive');
    }
  };
  return (
    <ul className="accordion job-accordion-ul">
      {data.map((item, index) => (
        <Fragment key={`job-accordion-${index}`}>
          <li className="item">
            <h2 className="sub-header accordionTitle" onClick={onClick}>
              <span className="accStatus" />
              {item.title} <span className="accIcon"></span>
            </h2>
            <div className="steer-body text">
              <FadeInSection timeout={100}>
                <BlockContent blocks={item._rawDescription} />
              </FadeInSection>
            </div>
          </li>
          <div className="vertical-space" />
        </Fragment>
      ))}
    </ul>
  );
};

export default JobAccordion;
