import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { navigate } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { FadeInSection } from '../';
import { AccordionItem } from '../../utils/globalTypes';
import './index.scss';

interface AccordionProps {
  data: AccordionItem[];
}

const Accordion = ({ data }: AccordionProps) => {
  console.log(data, 'accordion');
  useEffect(() => {
    onInitial();
  }, []);

  const onInitial = () => {
    const accordionBtn = document.querySelectorAll('.accordionTitle');
    const allTexts = document.querySelectorAll('.text');
    const accIcon = document.querySelectorAll('.accIcon');
    const accStatus = document.querySelectorAll('.accStatus');

    accIcon[0].classList.add('anime');
    accStatus[0].classList.add('accStatusActive');
    allTexts[0].classList.add('show');
    accordionBtn[0].classList.add('accordionTitleActive');

    accIcon[1].classList.add('anime');
    accStatus[1].classList.add('accStatusActive');
    allTexts[1].classList.add('show');
    accordionBtn[1].classList.add('accordionTitleActive');
  };

  const onClick = (event: any) => {
    const accordionBtn = document.querySelectorAll('.accordionTitle');
    const allTexts = document.querySelectorAll('.text');
    const accIcon = document.querySelectorAll('.accIcon');
    const accStatus = document.querySelectorAll('.accStatus');
    const targetText = event.currentTarget.nextElementSibling?.classList;
    const targetAccIcon = event.currentTarget.children[1];
    const targetAccStatus = event.currentTarget.children[0];
    const target = event.currentTarget;
    if (!targetText) return;
    if (targetText.contains('show')) {
      targetText.remove('show');
      targetAccIcon.classList.remove('anime');
      target.classList.remove('accordionTitleActive');
      targetAccStatus.classList.remove('accStatusActive');
    } else {
      // accordionBtn.forEach(function (el) {
      //   el.classList.remove('accordionTitleActive');

      //   allTexts.forEach(function (el) {
      //     el.classList.remove('show');
      //   });

      //   accIcon.forEach(function (el) {
      //     el.classList.remove('anime');
      //   });
      //   accStatus.forEach(function (el) {
      //     el.classList.remove('accStatusActive');
      //   });
      // });

      targetText.add('show');
      target.classList.add('accordionTitleActive');
      targetAccIcon.classList.add('anime');
      targetAccStatus.classList.add('accStatusActive');
    }
  };

  const onTransaction = () => {
    navigate('/services/transactional-advisory');
  };

  const onClickStrategic = () => {
    navigate('/services/strategic-growth');
  };

  return (
    <ul className="accordion accordion-ul">
      <li className="item">
        <h2 className="sub-header accordionTitle" onClick={onClick}>
          <span className="accStatus" />
          {data[0].title} <span className="accIcon"></span>
        </h2>
        <div className="steer-body text">
          <FadeInSection timeout={100}>
            <BlockContent blocks={data[0]._rawDescription} />
          </FadeInSection>
          <br />
          <Button className="read-more-button" onClick={onTransaction}>
            <div className="read-more">Read More</div>
          </Button>
        </div>
      </li>
      <div className="space" />
      <li className="item">
        <h2 className="sub-header accordionTitle" onClick={onClick}>
          <span className="accStatus" />
          {data[1].title} <span className="accIcon"></span>
        </h2>
        <div className="steer-body text">
          <BlockContent blocks={data[1]._rawDescription} />
          <br />
          <Button className="read-more-button" onClick={onClickStrategic}>
            <div className="read-more">Read More</div>
          </Button>
        </div>
      </li>
    </ul>
  );
};

export default Accordion;
