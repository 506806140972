import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H2, H4, H3, FadeInSection } from '../../';
import { SECTION_IDS } from '../../../constants';
import { ConsumerThirdType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: ConsumerThirdType;
  isLeftContent: boolean;
}

const ThirdSection = ({ content, isLeftContent }: Props) => {
  if (!isLeftContent) {
    return (
      <section id={SECTION_IDS.third} className="industry-sub-third1-section flex tertiary-background">
        <div className="main-container relative section-space">
          <div className="horizontal-section-space main-container1">
            <H2 className="white-text title">{content.title}</H2>
            <div className="flex main-sub-container">
              <div className="flex-1 flex">
                <div className="steer-vertical-label-container third-vertical-label">
                  <H4 className="rotated-text white-text" disableAnimation>
                    OUR INDUSTRIES
                  </H4>
                </div>
                <FadeInSection timeout={200}>
                  <RenderImage
                    imgUrl={content.mainImage.asset.url}
                    gatsbyImg={content.mainImage.asset.gatsbyImageData}
                    style={'third-image'}
                    sx={{ maxWidth: '830px' }}
                  />
                </FadeInSection>
              </div>
              <div className="space" />
              <div className="flex-1 flex flex-column section-list justify-center">
                {content.sectionItem.map((item, index) => {
                  return (
                    <div key={`section-item-${index}`}>
                      <H3 className="white-text">{item.title}</H3>
                      <FadeInSection timeout={200}>
                        <div className="block-container section-description">
                          <BlockContent blocks={item._rawDescription} />
                        </div>
                      </FadeInSection>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section id={SECTION_IDS.third} className="industry-sub-third1-section flex tertiary-background">
      <div className="main-container relative section-space">
        <div className="horizontal-section-space main-container1">
          <H2 className="white-text title">{content.title}</H2>
          <div className="flex main-sub-container">
            <div className="flex-1 flex flex-column section-list justify-center">
              {content.sectionItem.map((item, index) => {
                return (
                  <div key={`section-item-${index}`}>
                    <H3 className="white-text">{item.title}</H3>
                    <FadeInSection timeout={200}>
                      <div className="block-container section-description">
                        <BlockContent blocks={item._rawDescription} />
                      </div>
                    </FadeInSection>
                  </div>
                );
              })}
            </div>
            <div className="space" />
            <div className="flex-1 flex">
              <FadeInSection timeout={200}>
                <RenderImage
                  imgUrl={content.mainImage.asset.url}
                  gatsbyImg={content.mainImage.asset.gatsbyImageData}
                  style={'third-image'}
                  sx={{ maxWidth: '830px' }}
                />
              </FadeInSection>
              <div className="steer-vertical-right-label-container third-vertical-right-label">
                <H4 className="rotated-text white-text" disableAnimation>
                  OUR INDUSTRIES
                </H4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ThirdSection.defaultProps = {
  isLeftContent: false,
};

export default ThirdSection;
