import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { H2, H4, FadeInSection, NormalButton, RightArrowIcon } from '../../';
import { SECTION_IDS } from '../../../constants';
import { IndustrySecondType } from '../../../utils/globalTypes';
import '../Service/index.scss';
import './index.scss';

interface Props {
  content: IndustrySecondType;
}

const SecondSection = ({ content }: Props) => {
  const onLearnMore = (index: number) => {
    switch (index) {
      case 0:
        navigate(`/industries/consumer-growth`);
        break;
      case 1:
        navigate(`/industries/healthcare`);
        break;
      case 2:
        navigate(`/industries/industrial-markets`);
        break;
      case 3:
        navigate(`/industries/business-services`);
        break;
      default:
        break;
    }
  };
  return (
    <section id={SECTION_IDS.second} className="service-second-container section-space flex flex-column items-center">
      <div className="industry-title">
        <H2 className="text-center ">{content.title}</H2>
      </div>
      <div className="flex service-list">
        {content.industries.map((industry, index) => {
          return (
            <Fragment key={`service-${index}`}>
              <div className=" service-item">
                <div className="flex justify-between">
                  <H4 className="service-title">{industry.title}</H4>
                  <RightArrowIcon />
                </div>
                <div className="service-description steer-body">
                  <FadeInSection timeout={100}>
                    <BlockContent blocks={industry._rawDescription} />
                  </FadeInSection>
                </div>
                <NormalButton
                  type="light"
                  onClick={() => onLearnMore(index)}
                  label="Learn More"
                  textClassName="service-button-text"
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default SecondSection;
