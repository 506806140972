import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { H2, H4, BodyText, NormalButton, RightArrowIcon, FadeInSection } from '../../';
import { SECTION_IDS } from '../../../constants';
import { ServiceSecondType } from '../../../utils/globalTypes';
import './index.scss';

interface Props {
  content: ServiceSecondType;
}

const SecondSection = ({ content }: Props) => {
  const onLearnMore = (index: number) => {
    if (index === 0) {
      navigate('/services/transactional-advisory');
    } else {
      navigate('/services/strategic-growth');
    }
  };
  return (
    <section id={SECTION_IDS.second} className="service-second-container section-space flex flex-column items-center">
      <div className="second-title">
        <H2 className="text-center ">{content.title}</H2>
      </div>
      <div className="second-body">
        <BodyText className="text-center body-tertiary-text">{content.description}</BodyText>
      </div>
      <div className="flex service-list">
        {content.services.map((service, index) => {
          return (
            <Fragment key={`service-${index}`}>
              <div className="service-item">
                <div className="flex justify-between">
                  <H4 className="service-title">{service.title}</H4>
                  <RightArrowIcon />
                </div>
                <div className="service-description steer-body">
                  <FadeInSection timeout={100}>
                    <BlockContent blocks={service._rawDescription} />
                  </FadeInSection>
                </div>
                <NormalButton
                  type="light"
                  onClick={() => onLearnMore(index)}
                  label="Learn More"
                  textClassName="service-button-text"
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default SecondSection;
