import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SECTION_IDS } from '../../../constants';
import { H1 } from '../../';
import { ServiceFirstType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: ServiceFirstType;
}

const FirstSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.first} className="service-first-container relative">
      <RenderImage
        imgUrl={content.serviceImage.asset.url}
        gatsbyImg={content.serviceImage.asset.gatsbyImageData}
        style={'service-first-image'}
        sx={{ objectFit: 'cover', width: '100%' }}
      />
      <div className="flex absolute-full justify-center items-center">
        <H1 className="first-title flex flex-column items-center">{content.title}</H1>
      </div>
    </section>
  );
};

export default FirstSection;
