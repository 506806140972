import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SECTION_IDS } from '../../../constants';
import { H3, FadeInSection } from '../../';
import { OpportunityThirdType } from '../../../utils/globalTypes';
import RenderImage from '../../images';

interface Props {
  content: OpportunityThirdType;
}

const ThirdSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.second} className="opportunity-third-container flex">
      <div className="flex main-container section-space horizontal-section-space">
        <div className="flex-1 flex">
          <RenderImage
            imgUrl={content.mainImage.asset.url}
            gatsbyImg={content.mainImage.asset.gatsbyImageData}
            style={'second-image'}
            sx={{ maxWidth: '850px' }}
          />
        </div>
        <div className="space" />
        <div className="flex-1 flex justify-center flex-column">
          <H3 className="primary-text">{content.title}</H3>

          <FadeInSection timeout={200}>
            <div className="block-container">
              <BlockContent blocks={content._rawDescription} />
            </div>
          </FadeInSection>
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
