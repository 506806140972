export const MENU_ITEMS = [
  { label: 'Home', path: '/' },
  { label: 'ABOUT US', path: '/about' },
  { label: 'SERVICES', path: '/services' },
  { label: 'INDUSTRIES', path: '/industries' },
  { label: 'INSIGHTS', path: '/insights' },
  { label: 'CAREERS', path: '/opportunities' },
  { label: 'GET IN TOUCH', path: '/contact' },
];

export const MOBILE_MENU_ITEMS = [
  { label: 'Home', path: '/' },
  { label: 'About Us', path: '/about' },
  { label: 'Services', path: '/services' },
  { label: 'Industries', path: '/industries' },
  { label: 'Insights', path: '/insights' },
  { label: 'Careers', path: '/opportunities' },
  { label: 'Contact', path: '/contact' },
];

export const SERVICE_SUB_ITEMS = [
  { label: 'Transaction Advisory', path: '/services/transactional-advisory' },
  { label: 'Strategic Growth', path: '/services/strategic-growth' },
];

export const INDUSTRIES_SUB_ITEMS = [
  { label: 'Consumer Products', path: '/industries/consumer-growth' },
  { label: 'Healthcare', path: '/industries/healthcare' },
  { label: 'Industrial Markets', path: '/industries/industrial-markets' },
  { label: 'Business Services', path: '/industries/business-services' },
];

export const FOOTER_ITEMS = [
  [
    { label: 'Home', path: '/' },
    { label: 'About Us', path: '/about' },
  ],
  [
    { label: 'Services', path: '/services' },
    { label: 'Industries', path: '/industries' },
  ],
  [
    { label: 'Careers', path: '/opportunities' },
    { label: 'Get In Touch', path: '/contact' },
  ],
  [
    { label: 'Our Office', path: '/contact' },
    { label: 'LinkedIn', path: 'https://www.linkedin.com/company/steer-partners-llc' },
  ],
];

export const SECTION_IDS = {
  about: 'about',
  career: 'career',
  intro: 'intro',
  industry: 'industry',
  service: 'service',
  'get-in-touch': 'get-in-touch',
  first: 'first',
  second: 'second',
  third: 'third',
  fourth: 'fourth',
};

export const SEO_TEXT = {
  aboutTitle: 'About',
  aboutPageDescription: '',
  homeTitle: 'Home',
  homePageDescription: '',
  serviceTitle: 'Services',
  servicePageDescription: '',
  transactionalTitle: 'Transaction Advisory',
  transactionalDescription: '',
  strategicTitle: 'Strategic Growth',
  strategicDescription: '',
  industryTitle: 'Industries',
  industryPageDescription: '',
  consumerTitle: 'Consumer Growth',
  consumerDescription: '',
  healthcareTitle: 'Healthcare',
  healthcareDescription: '',
  industrialTitle: 'Industrial Markets',
  industrialDescription: '',
  businessServicesTitle: 'Business Services',
  businessServicesDescription: '',
  opportunitiesTitle: 'Opportunities',
  opportunitiesDescription: '',
};
