import React from 'react';
import { SECTION_IDS } from '../../../constants';
import { H1, H4 } from '../../';
import { TransactionalFirstType } from '../../../utils/globalTypes';
import './index.scss';

interface Props {
  content: TransactionalFirstType;
}

const FirstSection = ({ content }: Props) => {
  console.log(content);
  return (
    <section
      id={SECTION_IDS.first}
      className={`transactional-first-container flex items-center justify-center ${
        content.cateogry.toLowerCase().indexOf('services') !== -1 ? 'tertiary-background' : 'primary-background'
      }`}
    >
      <div className="main-container">
        <H4 className="white-text text-center">{content.cateogry}</H4>
        <H1 className="white-text first-title flex flex-column items-center">{content.title}</H1>
      </div>
    </section>
  );
};

export default FirstSection;
