import React from 'react';
import { Typography } from '@material-ui/core';
import FadeInSection from '../FadeInSection';

interface BodyTextProps {
  className?: any;
  children: any;
  timeout: number;
  disableAnimation: boolean;
}

export const BodyText = ({ className, children, timeout, disableAnimation, ...props }: BodyTextProps) => {
  const MainContent = () => {
    return (
      <Typography className={['steer-body', className].join(' ')} {...props}>
        {children}
      </Typography>
    );
  };
  if (disableAnimation) {
    return MainContent();
  }
  return <FadeInSection timeout={timeout}>{MainContent()}</FadeInSection>;
};
BodyText.defaultProps = {
  timeout: 100,
  disableAnimation: false,
};
