import React from 'react';

interface RightIconProps {
  color: string;
}

interface SteerTitleProps {
  type: 'mobile' | 'web';
  onClick: () => any;
}

interface FooterSteerProps {
  onClick: () => any;
}

const RightIcon = ({ color }: RightIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.191" height="12.02" viewBox="0 0 11.191 12.02">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Icon feather-arrow-right"
        transform="translate(1 1.414)"
      >
        <path d="M0 4.596h9.191" data-name="Path 9"></path>
        <path d="M4.596 0l4.6 4.6-4.6 4.6" data-name="Path 10"></path>
      </g>
    </svg>
  );
};

const SteerTitle = ({ type, onClick }: SteerTitleProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="111.465"
      height="30.301"
      data-name="Layer 2"
      viewBox="0 0 111.465 30.301"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <g data-name="Layer 1-2">
        <path
          fill="#fff"
          d="M64.206.838h21.677v5.855h-14.6v5.133h13.49v5.533H71.284v6.134h14.839v6.014H64.206zM48.662 23.491v-6.135H62.15v-5.532H48.662V6.692h14.6V.837H17.55A15.681 15.681 0 0012.259 0C5.85 0 .881 2.725.881 8.948c0 6.1 5.249 7.646 10.216 8.826 3.928.976 6.252 1.464 6.252 3.782 0 2.2-2.284 3.051-4.609 3.051-3.4 0-5.288-1.221-5.609-4.149H0C.16 26.883 5.168 30.3 12.78 30.3c6.611 0 11.739-3.374 11.739-9.477 0-6.223-4.807-7.891-10.137-9.151-4.286-.975-6.491-1.465-6.491-3.5 0-1.626 1.643-2.725 4.128-2.725 2.644 0 4.327 1.261 4.608 3.5h6.931a10.377 10.377 0 00-.3-2.1h2.409V29.5h7.423V6.852h8.5V29.5H63.5v-6.009zm61.569-2.084a5.773 5.773 0 00-4.27-5.253v-.119a6.879 6.879 0 005.091-6.935c0-5.333-4.434-8.259-10.264-8.259H86.747V12.38a11.863 11.863 0 018.929-5.953L95.4 3.648a.167.167 0 01.079-.158.17.17 0 01.178 0l4.058 2.494 4.16 2.559a.166.166 0 01.056.23.2.2 0 01-.029.036l-3.584 3.307-3.5 3.225a.169.169 0 01-.283-.108l-.281-2.846c-7.55.125-9.3 3.349-9.514 3.824v13.3h7.27V18.76h4.4c3.12 0 4.516 1.444 4.884 4.089.453 2.848.33 6.215 1.11 6.656h7.062v-.361c-1.028-.36-.618-3.969-1.235-7.739z"
          data-name="Path 12"
        ></path>
      </g>
    </svg>
  );
};

const FooterSteer = ({ onClick }: FooterSteerProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="145"
      height="40.017"
      data-name="Layer 2"
      viewBox="0 0 145 40.017"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <g data-name="Layer 1-2">
        <path
          fill="#fff"
          d="M83.523 1.107h28.2v7.732H92.735v6.779h17.543v7.307H92.731v8.1h19.3v7.942H83.523zM63.3 31.024v-8.1h17.549v-7.308H63.3V8.837h18.988V1.106H22.831A20.121 20.121 0 0015.947 0C7.61 0 1.146 3.6 1.146 11.817c0 8.056 6.829 10.1 13.289 11.656 5.109 1.289 8.133 1.933 8.133 5 0 2.9-2.972 4.029-6 4.029-4.429 0-6.879-1.613-7.3-5.48H0c.208 8.486 6.723 13 16.625 13 8.6 0 15.271-4.455 15.271-12.516 0-8.219-6.254-10.422-13.187-12.086-5.575-1.288-8.444-1.934-8.444-4.62 0-2.148 2.138-3.6 5.37-3.6 3.44 0 5.628 1.666 5.994 4.62h9.016a13.9 13.9 0 00-.39-2.768h3.134v29.914h9.656V9.05H54.1v29.916h28.5v-7.942zm80.092-2.752c-.533-3.76-2.242-5.825-5.554-6.937v-.157c4.435-1.536 6.623-4.767 6.623-9.161 0-7.043-5.767-10.908-13.352-10.908h-18.264V16.35a15.417 15.417 0 0111.616-7.862l-.355-3.669a.222.222 0 01.1-.209.218.218 0 01.232 0l5.28 3.29 5.411 3.38a.221.221 0 01.073.3.261.261 0 01-.038.047L130.5 16l-4.552 4.259a.22.22 0 01-.311-.012.228.228 0 01-.057-.131l-.365-3.758c-9.821.165-12.094 4.423-12.376 5.05v17.561h9.453V24.776h5.722c4.059 0 5.874 1.907 6.354 5.4.589 3.761.429 8.208 1.443 8.79H145v-.477c-1.337-.476-.8-5.242-1.606-10.221z"
          data-name="Path 12"
        ></path>
      </g>
    </svg>
  );
};

const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="14" viewBox="0 0 30 14">
      <g fill="#fff" data-name="Group 31" transform="translate(-313 -35)">
        <path d="M0 0H30V1H0z" data-name="Rectangle 227" transform="translate(313 35)"></path>
        <path d="M0 0H30V1H0z" data-name="Rectangle 228" transform="translate(313 48)"></path>
      </g>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="32" viewBox="0 0 15 32">
      <text fill="#fff" fontFamily="Helvetica" fontSize="32" transform="translate(0 31)">
        <tspan x="0" y="0">
          x
        </tspan>
      </text>
    </svg>
  );
};

const RightArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.132" height="25.96" viewBox="0 0 25.132 25.96">
      <g
        fill="none"
        stroke="#092c2e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Icon feather-arrow-right"
        transform="translate(1 1.414)"
      >
        <path d="M0 11.566h23.132" data-name="Path 9"></path>
        <path d="M11.566 0l11.566 11.566-11.566 11.566" data-name="Path 10"></path>
      </g>
    </svg>
  );
};

const NotFoundIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="219.762" height="120.264" viewBox="0 0 219.762 120.264">
      <g fill="#1d3a35" data-name="Group 31" transform="translate(8.813 -192.427)">
        <path
          d="M243.221 299.213l-6.787-16.014 11.146-4.878 15.367 35.531-35.6 15.026-4.779-11.193 16.146-6.956-63.593-25.6c2.843-3 5.279-5.906 8.11-8.353.782-.676 2.888-.368 4.134.126 17.407 6.893 34.769 13.9 52.143 20.872.98.389 1.97.762 3.713 1.439z"
          data-name="Path 12"
          transform="translate(-51.998 -23.734)"
        ></path>
        <path
          d="M-1.446 336.586l-7.367-9.179 62.654-50.457c.575 4.829 1.15 9.014 1.5 13.217a3.334 3.334 0 01-1.3 2.291Q26.74 314.476-.633 336.4a3.737 3.737 0 01-.813.186z"
          data-name="Path 13"
          transform="translate(0 -23.895)"
        ></path>
        <path
          d="M76.782 243.518l10.36-6.5 11.832 18.828c-4.891 0-9.317.062-13.736-.073-.6-.019-1.308-.94-1.732-1.6-2.206-3.393-4.329-6.84-6.724-10.655z"
          data-name="Path 14"
          transform="translate(-24.198 -12.606)"
        ></path>
        <path
          d="M155.046 207.233l20.5-14.806 8.473 8.615-28.63 6.958z"
          data-name="Path 15"
          transform="translate(-46.323)"
        ></path>
        <path
          d="M160.964 266.176l-8.268-8.888 18.29 2.1z"
          data-name="Path 16"
          transform="translate(-45.659 -18.336)"
        ></path>
        <path
          d="M110.794 317.384l-10.11 13.492-1.926-11.439z"
          data-name="Path 17"
          transform="translate(-30.41 -35.326)"
        ></path>
        <path
          d="M64.5 264.922l-19.856-10.673.648-1.2c7.54 1.29 15.094 2.551 19.208 11.873z"
          data-name="Path 18"
          transform="translate(-15.112 -17.138)"
        ></path>
      </g>
    </svg>
  );
};

RightIcon.defaultProps = {
  color: '#fff',
};

export { RightIcon, SteerTitle, MenuIcon, CloseIcon, FooterSteer, RightArrowIcon, NotFoundIcon };
