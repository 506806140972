import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './index.scss';
import { H4, H3, BodyText, NormalButton } from '../../..';
import { IndustrySectionType } from '../../../../utils/globalTypes';
import { SECTION_IDS } from '../../../../constants';
import RenderImage from '../../../images';

interface IndustryProps {
  content: IndustrySectionType;
}

const Industry = ({ content }: IndustryProps) => {
  const onIndustry = () => {
    navigate('/industries');
  };
  return (
    <section id={SECTION_IDS.industry} className="industry-container">
      <div className="industry-main-container section-space flex justify-center">
        <div className="steer-vertical-label-container industry-main-left-label">
          <H4 className="rotated-text" disableAnimation>
            WHAT WE HELP
          </H4>
        </div>
        <RenderImage
          imgUrl={content.industryImage.asset.url}
          gatsbyImg={content.industryImage.asset.gatsbyImageData}
          style={'industry-image'}
        />
        <div className="industry-info-container flex items-center justify-center">
          <div className="industry-main-info-container">
            <H3>{content.title}</H3>
            <BodyText className="about-description">{content.description}</BodyText>
            <NormalButton label="INDUSTRIES WE WORK WITH" type="tertiary" onClick={onIndustry} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industry;
