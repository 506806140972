import React from 'react';
import { navigate } from 'gatsby';
import { H3, BodyText, NormalButton } from '../../..';
import './index.scss';
import { GetInTouchSectionType } from '../../../../utils/globalTypes';
import { SECTION_IDS } from '../../../../constants';

interface GetInTouchProps {
  content: GetInTouchSectionType;
}

const GetInTouch = ({ content }: GetInTouchProps) => {
  const onClick = (index: number) => {
    if (index === 0) {
      navigate('/opportunities');
    } else {
      navigate('/contact');
    }
  };
  return (
    <section id={SECTION_IDS['get-in-touch']} className="getInTouch-container flex">
      <div className="get-main-container flex">
        {content.getInTouch.map((item, index) => {
          return (
            <div
              key={`get-in-touch-${index}`}
              className={`get-item-container ${index === 0 ? 'tertiary-background' : 'lightGray-background'}`}
            >
              <div className="absolute-full justify-center items-center flex flex-column">
                <div className="get-main-item-container ">
                  <H3 className={index === 0 ? 'white-title-text' : 'tertiary-title-text'}>{item.title}</H3>
                  <BodyText
                    className={`item-space ${index === 0 ? 'white-description-text' : 'tertiary-description-text'}`}
                  >
                    {item.description}
                  </BodyText>
                  <NormalButton
                    type={index === 0 ? 'light' : 'normal'}
                    label={item.buttonLabel}
                    buttonClassName="item-space"
                    onClick={() => onClick(index)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default GetInTouch;
