import React from 'react';
import { Button } from '@material-ui/core';
import { navigate } from 'gatsby';
import { H2, H4, BodyText, NormalButton, RightArrowIcon } from '../';
import './index.scss';

const ITEMS = ['Consumer Products', 'Healthcare', 'Industrial Markets', 'Business Services'];

interface Props {
  activeIndex: number;
}

const IndustrySubMenu = ({ activeIndex }: Props) => {
  const onClick = (index: number) => {
    switch (index) {
      case 0:
        navigate(`/industries/consumer-growth`);
        break;
      case 1:
        navigate(`/industries/healthcare`);
        break;
      case 2:
        navigate(`/industries/industrial-markets`);
        break;
      case 3:
        navigate(`/industries/business-services`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="indsutry-sub-menu flex dark-background justify-center items-center">
      {ITEMS.map((item, index) => (
        <Button key={`key-${index}`} className="menu-button" disableRipple onClick={() => onClick(index)}>
          <H4 className={activeIndex === index ? 'active-text' : 'item-text'}>{item}</H4>
        </Button>
      ))}
    </div>
  );
};

export default IndustrySubMenu;
