import React from 'react';
import { Typography } from '@material-ui/core';
import FadeInSection from '../FadeInSection';

interface HeaderProps {
  className?: any;
  children: any;
  timeout: number;
  disableAnimation: boolean;
  onClick?: () => void;
}

export const H1 = ({ className, children, timeout, disableAnimation, ...props }: HeaderProps) => {
  const MainContent = () => {
    return (
      <Typography className={['steer-h1', className].join(' ')} {...props}>
        {children}
      </Typography>
    );
  };
  if (disableAnimation) {
    return MainContent();
  }
  return <FadeInSection timeout={timeout}>{MainContent()}</FadeInSection>;
};

export const H2 = ({ className, children, timeout, disableAnimation, ...props }: HeaderProps) => {
  const MainContent = () => {
    return (
      <Typography className={['steer-h2', className].join(' ')} {...props}>
        {children}
      </Typography>
    );
  };
  if (disableAnimation) {
    return MainContent();
  }
  return <FadeInSection timeout={timeout}>{MainContent()}</FadeInSection>;
};

export const H3 = ({ className, children, timeout, disableAnimation, ...props }: HeaderProps) => {
  const MainContent = () => {
    return (
      <Typography className={['steer-h3', className].join(' ')} {...props}>
        {children}
      </Typography>
    );
  };
  if (disableAnimation) {
    return MainContent();
  }
  return <FadeInSection timeout={timeout}>{MainContent()}</FadeInSection>;
};

export const H4 = ({ className, children, timeout, disableAnimation, onClick, ...props }: HeaderProps) => {
  const MainContent = () => {
    return (
      <Typography className={['steer-h4', className].join(' ')} onClick={onClick} {...props}>
        {children}
      </Typography>
    );
  };
  if (disableAnimation) {
    return MainContent();
  }
  return <FadeInSection timeout={timeout}>{MainContent()}</FadeInSection>;
};

H1.defaultProps = {
  timeout: 100,
  disableAnimation: false,
};

H2.defaultProps = {
  timeout: 100,
  disableAnimation: false,
};

H3.defaultProps = {
  timeout: 100,
  disableAnimation: false,
};

H4.defaultProps = {
  timeout: 100,
  disableAnimation: false,
};
