import React from 'react';

interface LinkProps {
  children: React.ReactNode;
  mark: {
    url: string;
  };
}

const sanitySerializers = {
  types: {},
  marks: {
    link: ({ children, mark }: LinkProps) => {
      return <a href={mark.url}>{children}</a>;
    },
    externalLink: ({ children, mark }: LinkProps) => {
      return (
        <a href={mark.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  },
};

export { sanitySerializers };
