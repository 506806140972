import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { SECTION_IDS } from '../../../constants';
import { H2, JobAccordion, FadeInSection } from '../..';
import { OpportunitySecondType } from '../../../utils/globalTypes';
import { sanitySerializers } from '../../../utils/sanity';

interface Props {
  content: OpportunitySecondType;
}

const SecondSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.first} className="opportunity-second-container section-space horizontal-section-space">
      <H2 className="title">{content.title}</H2>
      <FadeInSection timeout={100}>
        <JobAccordion data={content.accordionItem} />
      </FadeInSection>
      <FadeInSection timeout={200}>
        <div className="opportunity-block steer-h3 flex justify-center items-center">
          <BlockContent blocks={content._rawContactUs} serializers={sanitySerializers} />
        </div>
      </FadeInSection>
    </section>
  );
};

export default SecondSection;
