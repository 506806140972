import React from 'react';
import { H2, H3, Accordion } from '../../..';
import { ServiceSectionType } from '../../../../utils/globalTypes';
import { SECTION_IDS } from '../../../../constants';

import './index.scss';

interface ServiceProp {
  content: ServiceSectionType;
}

const ServiceSection = ({ content }: ServiceProp) => {
  return (
    <section id={SECTION_IDS.service} className="service-container relative">
      <div className="service-main-container section-space">
        <div className="service-title flex">
          <H2>{content.title}</H2>
        </div>

        <div className="service-accordion">
          <div className={'accordion-title'}>
            <H3>{content.accordion.title}</H3>
          </div>
          <div className={'main-accordion'}>
            <Accordion data={content.accordion.accordionItem} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
