import React from 'react';
// import AnimatedNumber from 'animated-number-react';
import { SECTION_IDS } from '../../../constants';
import { H1, H3, BodyText, AnimatedNumber } from '../..';
import { AboutThirdType, StatisticItem } from '../../../utils/globalTypes';
import './index.scss';

interface Props {
  content: AboutThirdType;
}

interface ItemProps {
  item: StatisticItem;
  index: number;
}

const AboutThirdSection = ({ content }: Props) => {
  const StatisticItem = ({ item, index }: ItemProps) => {
    const formatValue = (value: any) => {
      return item.value.includes('+') ? `${Number(value).toFixed(0)}+` : Number(value).toFixed(0);
    };

    return (
      <div className="statisticItem flex-1 justify-center items-center">
        <AnimatedNumber item={item} timeout={0} className="item-value-text text-center white-text steer-h1" />
        <H3 className="text-center white-text">{item.label}</H3>
      </div>
    );
  };

  return (
    <section id={SECTION_IDS.third} className="about-third-container flex">
      {content.statistics.map((item, index) => (
        <StatisticItem item={item} index={index} key={`staticsticItem-${index}`} />
      ))}
    </section>
  );
};

export default AboutThirdSection;
