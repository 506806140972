import React, { useState, Fragment } from 'react';
import { navigate } from 'gatsby';
import { AppBar, Typography, Button, Hidden, IconButton, Drawer, Box } from '@material-ui/core';
import { SteerTitle, MenuIcon, CloseIcon } from '../../Icons';
import { MOBILE_MENU_ITEMS, SECTION_IDS, SERVICE_SUB_ITEMS, INDUSTRIES_SUB_ITEMS } from '../../../constants';
import { H4 } from '../../';

interface Props {
  activePath: string;
}

const MobileHeader = ({ activePath }: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showServiceMenu, setVisibleServiceMenu] = useState(false);
  const [showIndustryMenu, setVisibleIndustryMenu] = useState(false);

  const onShowMenu = () => setMobileOpen(!mobileOpen);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClickMenuItem = (menuItem: { label: string; path: string }, index: number) => {
    navigate(menuItem.path);
    if (index === 2) {
      setVisibleServiceMenu(!showServiceMenu);
    } else if (index === 3) {
      setVisibleIndustryMenu(!showIndustryMenu);
    } else {
      setMobileOpen(false);
    }
  };

  const onClickSubItem = (menuItem: { label: string; path: string }) => {
    navigate(menuItem.path);
    setMobileOpen(false);
  };

  const onClickLogo = () => navigate('/');

  const onClickGetInTouch = () => {
    navigate(`/contact`);
    setMobileOpen(false);
  };

  return (
    <Box sx={{ display: { sm: 'block', md: 'none' } }}>
      <div>
        <div className="header-container">
          <div className="header-logo">
            <SteerTitle type="mobile" onClick={onClickLogo} />
          </div>
          <IconButton onClick={onShowMenu} className="mobile-menu-icon">
            <MenuIcon />
          </IconButton>
        </div>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: 'drawerPaper' }}
          className="drawer"
          ModalProps={{ keepMounted: true }}
        >
          <IconButton onClick={onShowMenu} className="mobile-menu-icon mobile-menu-close-icon">
            <CloseIcon />
          </IconButton>
          <div className="menu-container">
            {MOBILE_MENU_ITEMS.map((item, index) => (
              <Fragment key={`key-${index}`}>
                <Button
                  className={index === 2 || index === 3 ? 'menu-button-1 header-button' : 'menu-button header-button'}
                  onClick={() => onClickMenuItem(item, index)}
                  disableRipple
                >
                  <Typography
                    className={
                      activePath !== item.path
                        ? 'header-text menu-item-text'
                        : 'header-text menu-item-text header-underline'
                    }
                  >
                    {item.label}
                  </Typography>
                </Button>
                {index === 2 && (
                  <div className="transition menu-sub-container flex flex-column items-center justify-center">
                    {showServiceMenu &&
                      SERVICE_SUB_ITEMS.map((subItem, index) => (
                        <H4
                          className={
                            activePath === subItem.path ? 'text-left-mobile menu-underline' : 'text-left-mobile'
                          }
                          onClick={() => onClickSubItem(subItem)}
                          key={`service-subItem-${index}`}
                        >
                          {subItem.label}
                        </H4>
                      ))}
                  </div>
                )}
                {index === 3 && (
                  <div className="transition menu-sub-container">
                    {showIndustryMenu &&
                      INDUSTRIES_SUB_ITEMS.map((subItem, index) => (
                        <H4
                          className={
                            activePath === subItem.path ? 'text-left-mobile menu-underline' : 'text-left-mobile'
                          }
                          onClick={() => onClickSubItem(subItem)}
                          key={`service-subItem-${index}`}
                        >
                          {subItem.label}
                        </H4>
                      ))}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </Drawer>
      </div>
    </Box>
  );
};

export default MobileHeader;
