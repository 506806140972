const throttle = (fn, time) => {
  let busy = false;

  return (...args) => {
    if (!busy) {
      setTimeout(() => {
        fn(...args);
        busy = false;
      }, time);

      busy = true;
    }
  };
};

export { throttle };
