import React from 'react';
import { navigate } from 'gatsby';
import { H2, H4, BodyText, NormalButton, RightArrowIcon } from '../../';
import { SECTION_IDS } from '../../../constants';
import { ServiceThirdType } from '../../../utils/globalTypes';
import '../Service/index.scss';
import './index.scss';

interface Props {
  content: ServiceThirdType;
}

const ThirdSection = ({ content }: Props) => {
  const onClick = () => {
    navigate('/contact');
  };
  return (
    <section id={SECTION_IDS.third} className="service-third-container flex justify-center">
      <div className="main-container flex items-center justify-between">
        <div className="main-sub-container">
          <H2 className="third-text">{content.title}</H2>
          <H2 className="third-text">{content.subTitle}</H2>
        </div>
        <NormalButton label={content.buttonText} type="light" textClassName="button-text" onClick={onClick} />
      </div>
    </section>
  );
};

export default ThirdSection;
