import React from 'react';
import { SECTION_IDS } from '../../../constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H2, H3, BodyText } from '../..';
import { AboutSecondType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: AboutSecondType;
}

const AboutSecondSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.second} className="about-second-container flex justify-end">
      <div className="main-container vertical-space-1">
        <div className="main-sub-container">
          <H2>{content.title}</H2>
          {content.differentiators.map((item, index) => {
            return (
              <div className="item-container flex" key={`icon-${index}`}>
                <div className="icon-container flex justify-center items-center">
                  <RenderImage
                    imgUrl={item.listIcon.asset.url}
                    gatsbyImg={item.listIcon.asset.gatsbyImageData}
                    style={'icon'}
                  />
                </div>
                <div className="item-info-container flex-1">
                  <H3>{item.title}</H3>
                  <BodyText className="item-description-text">{item.description}</BodyText>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AboutSecondSection;
