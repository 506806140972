import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { H2, H4, H3, NormalButton, RightArrowIcon } from '../../';
import { SECTION_IDS } from '../../../constants';
import { StrategicThirdType } from '../../../utils/globalTypes';

import './index.scss';

interface Props {
  content: StrategicThirdType;
}

const ThirdSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.third} className="strategic-third-container flex">
      <div className="main-container flex section-space items-center">
        <div className="flex-1">
          <H2 className="title">{content.title}</H2>
        </div>
        <div className="space" />
        <div className="second-block">
          <H3>{content.subTitle}</H3>
          <BlockContent blocks={content._rawDescription} />
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
