import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H1, BodyText } from '../..';
import { SECTION_IDS } from '../../../constants';
import { InsightsFirstType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: InsightsFirstType;
}

const InsightsFirstSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.first} className="about-first-container">
      <div className="absolute background-color-container primary-background" />
      <div className="main-container flex">
        <div className="info-container flex-1 flex flex-column items-center justify-center">
          <div className="info-sub-container">
            <H1 className="white-text ">{content.title}</H1>
          </div>
        </div>
        <div className="img-container flex-1 relative">
          <RenderImage
            imgUrl={content.image.asset.url}
            gatsbyImg={content.image.asset.gatsbyImageData}
            style={'first-image'}
          />
        </div>
      </div>
    </section>
  );
};

export default InsightsFirstSection;
