import React, { Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';
import './index.scss';

interface LayoutProps {
  children: any;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <main className="layout-container flex flex-column">
      <Header />
      <div className="layout-main-container flex-1">{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
