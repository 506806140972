import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import { H1, H3, BodyText, InputText, NormalButton, FadeInSection } from '../../';
import { SECTION_IDS } from '../../../constants';
import { SanityContactType } from '../../../utils/globalTypes';
import { sanitySerializers } from '../../../utils/sanity';

import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: SanityContactType;
}

const FirstSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.first} className="contact-first-container relative flex">
      <div className="header-space absolute" />
      <div className="main-container flex section-space horizontal-section-space">
        <div className="left-container flex flex-column">
          <H1 className="title-text">{content.title}</H1>
          <FadeInSection timeout={100}>
            <div className="steer-body block-description">
              <BlockContent blocks={content._rawDescription} serializers={sanitySerializers} />
            </div>
          </FadeInSection>

          <div className="info-container">
            <H3 className="uppercase">{content.contact.title}</H3>
            <div className="sub-container">
              <a href={`tel:${content.contact.tel}`} className="non-underline">
                <BodyText className="small-body">Tel: {content.contact.tel}</BodyText>
              </a>
              <a href={`mailto:${content.contact.email}`} className="non-underline">
                <BodyText className="small-body">Email: {content.contact.email}</BodyText>
              </a>
            </div>
          </div>
          <div className="info-container">
            <H3 className="uppercase">{content.address.title}</H3>
            <div className="sub-container">
              <BodyText className="small-body">{content.address.address1}</BodyText>
              <BodyText className="small-body">{content.address.address2}</BodyText>
            </div>
          </div>
          <FadeInSection timeout={100}>
            <a href={content.address.mainImage.url} target="_blank">
              <RenderImage
                imgUrl={content.address.mainImage.asset.url}
                gatsbyImg={content.address.mainImage.asset.gatsbyImageData}
                style={'address-image'}
              />
            </a>
          </FadeInSection>
        </div>
        <div className="space"></div>
        <div className="right-container 1">
          <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <FadeInSection timeout={100}>
              <BodyText className="tertiary-light-color">{content.inputLabel}</BodyText>
              <div className="flex">
                <InputText
                  required
                  label="First Name"
                  defaultValue=""
                  variant="filled"
                  className="input-field flex-1"
                  name="firstName"
                  id="firstName"
                />
                <div className="text-horizontal-space" />
                <InputText
                  required
                  label="Last Name"
                  defaultValue=""
                  variant="filled"
                  className="input-field flex-1"
                  name="lastName"
                  id="lastName"
                />
              </div>
              <InputText
                required
                label="Your Email"
                defaultValue=""
                variant="filled"
                className="input-field flex-1"
                fullWidth
                name="email"
                id="email"
              />
              <InputText
                label="Your Organization"
                defaultValue=""
                variant="filled"
                className="input-field flex-1"
                fullWidth
                name="organization"
                id="organization"
              />
              <div className="flex">
                <InputText
                  label="Job Title"
                  defaultValue=""
                  variant="filled"
                  className="input-field flex-1"
                  name="jobTitle"
                  id="jobTitle"
                />
                <div className="text-horizontal-space" />
                <InputText
                  label="Phone Number"
                  defaultValue=""
                  variant="filled"
                  className="input-field flex-1"
                  name="phone"
                  id="phone"
                />
              </div>
              <InputText
                label="Subject"
                defaultValue=""
                variant="filled"
                className="input-field flex-1"
                fullWidth
                name="subject"
                id="subject"
              />
              <InputText
                label="Type your message here..."
                defaultValue=""
                variant="filled"
                className="multiline-field input-field flex-1"
                fullWidth
                multiline
                name="message"
                id="message"
              />
              <div className="button-container flex justify-end">
                <NormalButton label="SEND YOUR MESSAGE" type="normal" buttonType="submit" />
              </div>
            </FadeInSection>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
