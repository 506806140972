import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Button, Hidden } from '@material-ui/core';
import { SteerTitle } from '../../Icons';
import './index.scss';
import { MENU_ITEMS } from '../../../constants';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { throttle } from '../../../lib/helpers';

const Header = () => {
  const [windowIsScrolled, setWindowIsScrolled] = useState(false);

  const windowScrollHandler = (event: any) => {
    setWindowIsScrolled(window?.scrollY > 20);
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(windowScrollHandler, 300));
  }, []);

  return (
    <AppBar position="fixed" className={`header ${windowIsScrolled ? 'active-background' : ''}`}>
      <div className="hideIns">
        <DesktopHeader activePath={window.location.pathname} />
      </div>
      <MobileHeader activePath={window.location.pathname} />
    </AppBar>
  );
};

export default Header;
