import React from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';

import { RightIcon } from '../Icons';

const DarkButton = () => {
  return (
    <Button className="dark-button">
      <Typography className="button-text dark">PRIMARY BUTTON - DARK</Typography>
      <div className="button-icon">
        <RightIcon color="white" />
      </div>
    </Button>
  );
};

const LightButton = () => {
  return (
    <Button className="light-button">
      <Typography className="button-text light">PRIMARY BUTTON - LIGHT</Typography>
      <div className="button-icon">
        <RightIcon color="#092C2E" />
      </div>
    </Button>
  );
};

const TertiaryButton = () => {
  return (
    <Button className="tertiary-button">
      <Typography className="button-text dark">TERTIARY button</Typography>
      <div className="button-icon">
        <RightIcon color="white" />
      </div>
    </Button>
  );
};

interface NormalButtonProps {
  type: 'normal' | 'light' | 'tertiary';
  label: string;
  buttonClassName?: any;
  textClassName?: any;
  iconColor?: string;
  onClick?: () => any;
  buttonType?: 'submit' | 'reset' | 'button';
}

const NormalButton = ({
  type,
  label,
  buttonClassName,
  textClassName,
  iconColor,
  onClick,
  buttonType,
  ...props
}: NormalButtonProps) => {
  let defaultClassName = '';
  let defaultTextClassName = '';
  let defaultIconColor = 'white';
  if (type === 'normal') {
    defaultClassName = 'dark-button';
    defaultTextClassName = 'dark';
  } else if (type === 'light') {
    defaultClassName = 'light-button';
    defaultTextClassName = 'light';
    defaultIconColor = '#092C2E';
  } else if (type === 'tertiary') {
    defaultClassName = 'tertiary-button';
    defaultTextClassName = 'dark';
  }
  return (
    <Button className={`${defaultClassName} ${buttonClassName}`} onClick={onClick} type={buttonType}>
      <Typography className={`button-text ${defaultTextClassName} ${textClassName}`}>{label}</Typography>
      <div className="button-icon">
        <RightIcon color={iconColor || defaultIconColor} />
      </div>
    </Button>
  );
};

NormalButton.defaultProps = {
  buttonType: 'button',
};

export { DarkButton, LightButton, TertiaryButton, NormalButton };
