import React, { useRef, useEffect } from 'react';
import { H1, FadeInSection } from '../';
import AnimatNumber from 'animated-number-react';
import { AboutThirdType, StatisticItem } from '../../utils/globalTypes';

interface Props {
  item: StatisticItem;
  timeout: number;
  className: string;
}

const AnimatedNumber = ({ item, timeout, className }: Props) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setTimeout(
          () => {
            if (entry.isIntersecting) {
              setVisible(entry.isIntersecting);
            }
          },
          timeout ? timeout : 0,
        );
      });
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }
    return () => {
      domRef.current ? observer.unobserve(domRef.current) : null;
    };
  }, []);
  const formatValue = (value: any) => {
    return item.value.includes('+') ? `${Number(value).toFixed(0)}+` : Number(value).toFixed(0);
  };

  return (
    <div ref={domRef} className="flex justify-center">
      <FadeInSection timeout={timeout}>
        {isVisible && (
          <AnimatNumber value={parseInt(item.value)} formatValue={formatValue} duration={1500} className={className} />
        )}
      </FadeInSection>
    </div>
  );
};

export default AnimatedNumber;
