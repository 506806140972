import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AboutUsSectionType } from '../../../../utils/globalTypes';
import { H4, H2, H3, BodyText, NormalButton } from '../../..';
import { SECTION_IDS } from '../../../../constants';
import './index.scss';
import RenderImage from '../../../images';
interface AboutUsProps {
  content: AboutUsSectionType;
}

const AboutUs = ({ content }: AboutUsProps) => {
  const onAbout = () => {
    navigate('/about');
  };

  return (
    <section id={SECTION_IDS.about} className="about-container relative">
      <div className="about-main-container section-space flex justify-center">
        <div className="steer-vertical-label-container about-main-left-label">
          <H4 className="rotated-text" disableAnimation>
            WHAT WE DO
          </H4>
        </div>
        <RenderImage
          imgUrl={content.aboutUsBackgroundImage.asset.url}
          gatsbyImg={content.aboutUsBackgroundImage.asset.gatsbyImageData}
          style={'aboutUs-image'}
        />

        <div className="about-info-container">
          <H2 className="info-title">{content.title}</H2>
          <RenderImage
            imgUrl={content.aboutUsBackgroundImage.asset.url}
            gatsbyImg={content.aboutUsBackgroundImage.asset.gatsbyImageData}
            style={'aboutUs-mobile-image'}
          />

          <div className="about-sub-info-container">
            <H3>{content.subTitle}</H3>
            <BodyText className="about-description">{content.description}</BodyText>
            <NormalButton label="WHAT WE DO" type="normal" textClassName="about-button-text" onClick={onAbout} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
