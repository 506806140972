import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AboutSixthType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: AboutSixthType;
}

const SixthSection = ({ content }: Props) => {
  return (
    <div className="about-sixth-container flex items-center justify-center">
      <RenderImage
        imgUrl={content.teamImage.asset.url}
        gatsbyImg={content.teamImage.asset.gatsbyImageData}
        style={'first-image'}
      />
    </div>
  );
};

export default SixthSection;
