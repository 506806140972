import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import { SECTION_IDS } from '../../../constants';
import { H3, H4 } from '../../';
import { TransactionalSecondType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: TransactionalSecondType;
}

const SecondSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.second} className="transactional-second-container section-space horizontal-section-space">
      <div className="flex main-container">
        <div className="flex-1 flex justify-center flex-column">
          <H3 className="primary-text">{content.title}</H3>
          <div className="block-container">
            <BlockContent blocks={content._rawDescription} />
          </div>
        </div>
        <div className="space" />
        <div className="flex-1 flex">
          <RenderImage
            imgUrl={content.mainImage.asset.url}
            gatsbyImg={content.mainImage.asset.gatsbyImageData}
            style={'second-image'}
          />
          <div className="steer-vertical-right-label-container second-vertical-label">
            <H4 className="rotated-text" disableAnimation>
              OUR SERVICES
            </H4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondSection;
