import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import './index.scss';
import { FooterSteer } from '../../Icons';
import { FOOTER_ITEMS } from '../../../constants';

const Footer = () => {
  const onGoHome = () => navigate('/');

  return (
    <div className="footer-container">
      <Typography className="footer-title footer-text">
        Trusted and knowledgable
        <br />
        solutions, <span className="light-gray-text">tailored to you.</span>
      </Typography>
      <div className="footer-main-container">
        <div className="footer-logo">
          <FooterSteer onClick={onGoHome} />
        </div>
        {FOOTER_ITEMS.map((link, index) => (
          <div key={`key-${index}`} className="footer-item-container">
            {link.map((i, j) => {
              return i.path.includes('http') ? (
                <a href={i.path} target="_blank" className="non-underline" key={i.path}>
                  <Typography key={`${index}-${j}`} className="steer-body footer-item-text relative">
                    {i.label}
                  </Typography>
                </a>
              ) : (
                <Link to={i.path} className="non-underline" key={i.path}>
                  <Typography key={`${index}-${j}`} className="steer-body footer-item-text relative">
                    {i.label}
                  </Typography>
                </Link>
              );
            })}
          </div>
        ))}
      </div>
      <Typography className="footer-copyright-text steer-body">
        Copyright 2021 • STEER • All Rights Reserved • Licenses • Privacy Policy • Terms of Use
      </Typography>
    </div>
  );
};

export default Footer;
