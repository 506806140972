import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H1, BodyText } from '../..';
import { SECTION_IDS } from '../../../constants';
import { OpportunityFirstType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: OpportunityFirstType;
}

const FirstSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.first} className="opportunity-first-container">
      <div className="absolute background-color-container tertiary-background" />
      <div className="main-container flex section-space horizontal-section-space">
        <div className="info-container flex-1 flex flex-column items-center justify-center">
          <div className="info-sub-container">
            <H1 className="white-text">{content.title}</H1>
            <BodyText className="body-white-text vertical-space-1">{content.description}</BodyText>
          </div>
        </div>
        <div className="space" />
        <div className="img-container flex-1 relative">
          <RenderImage
            imgUrl={content.mainImage.asset.url}
            gatsbyImg={content.mainImage.asset.gatsbyImageData}
            style={'first-image'}
          />
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
