import React from 'react';
import { H2, H1, H4 } from '../../..';
import { CareerSectionType } from '../../../../utils/globalTypes';
import './index.scss';
import { SECTION_IDS } from '../../../../constants';

interface CareerProps {
  content: CareerSectionType;
}

const Career = ({ content }: CareerProps) => {
  return (
    <section id={SECTION_IDS.career} className="career-container flex items-center flex-column">
      <div className="career-main-container section-space flex items-center flex-column">
        <H1 className="career-quotation text-center">“</H1>
        <H2 className="text-center career-text">{content.quotation}</H2>
        <H4 className="career-provider">{content.provider}</H4>
      </div>
    </section>
  );
};

export default Career;
