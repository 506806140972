import React, { useRef, useEffect } from 'react';
import './index.scss';

interface FadeInSectionProps {
  children: any;
  timeout: number;
}

const FadeInSection = (props: FadeInSectionProps) => {
  const { children, timeout } = props;
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setTimeout(
          () => {
            if (entry.isIntersecting) {
              setVisible(entry.isIntersecting);
            }
          },
          timeout ? timeout : 0,
        );
      });
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }
    return () => {
      domRef.current ? observer.unobserve(domRef.current) : null;
    };
  }, []);
  return (
    <div className={['fade-in-section', isVisible ? 'is-visible' : ''].join(' ')} ref={domRef}>
      {children}
    </div>
  );
};

export default FadeInSection;
