import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './index.scss';
import { H1, NormalButton } from '../../..';
import { IntroSectionType } from '../../../../utils/globalTypes';
import { SECTION_IDS } from '../../../../constants';
import RenderImage from '../../../images';

interface Props {
  content: IntroSectionType;
}

const Intro = ({ content }: Props) => {
  const onTransaction = () => {
    navigate('/services/transactional-advisory');
  };

  const onClickStrategic = () => {
    navigate('/services/strategic-growth');
  };

  return (
    <section id={SECTION_IDS.intro} className="home-container relative">
      <RenderImage
        imgUrl={content.introBackgroundImage.asset.url}
        gatsbyImg={content.introBackgroundImage.asset.gatsbyImageData}
        style={'intro-image'}
      />
      <div className="home-main-container absolute-full flex items-center">
        <div className="sub-container">
          <H1 className="home-title">{content.title}</H1>
          <div className="flex home-buttons">
            <NormalButton
              type="normal"
              label="TRANSACTION ADVISORY"
              buttonClassName="left-button"
              onClick={onTransaction}
            />
            <NormalButton type="light" label="STRATEGIC GROWTH" onClick={onClickStrategic} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
