import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const RenderImage = ({
  imgUrl,
  gatsbyImg,
  style,
  w,
  h,
  sx,
}: {
  imgUrl: string | undefined;
  gatsbyImg: any;
  style: string;
  sx?: React.CSSProperties | undefined;
  w?: string | number;
  h?: string | number;
}) => {
  return (
    <>
      {imgUrl !== undefined ? (
        <img src={imgUrl} alt={'intro image'} className={style} style={sx} />
      ) : (
        <GatsbyImage image={gatsbyImg} alt={'intro image'} className={style} />
      )}
    </>
  );
};

export default RenderImage;
