import React from 'react';
import { TextField, TextFieldProps, makeStyles } from '@material-ui/core';
import { FadeInSection } from '..';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiFormLabel-root': {
      fontFamily: 'CustomLato, Trebuchet MS, sans-serif',
    },
    '& .MuiInputBase-root': {
      fontFamily: 'CustomLato, Trebuchet MS, sans-serif',
    },
    '& .MuiFilledInput-root': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      backgroundColor: '#CECBE64C',
    },
    '& .MuiFilledInput-root:hover': {
      backgroundColor: '#CECBE68C',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: '1px solid #2D4798',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomWidth: '1px',
    },
    marginTop: '20px',
  },
}));

const InputText = ({ required, label, defaultValue, id, variant, className, ...res }: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      required={required}
      id={id || ''}
      label={label}
      defaultValue={defaultValue}
      variant={variant}
      className={`${classes.textField} ${className}`}
      {...res}
    />
  );
};

export default InputText;
