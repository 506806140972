import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import { SECTION_IDS } from '../../../constants';
import { H2, H4 } from '../../';
import { TransactionalThirdType } from '../../../utils/globalTypes';
import './index.scss';
import RenderImage from '../../images';

interface Props {
  content: TransactionalThirdType;
}

const ThirdSection = ({ content }: Props) => {
  return (
    <section id={SECTION_IDS.third} className="transactional-third-container flex justify-center">
      <div className="main-container flex flex-column items-center section-space">
        <H2 className="text-center title">{content.title}</H2>

        <RenderImage
          imgUrl={content.infographicImage.asset.url}
          gatsbyImg={content.infographicImage.asset.gatsbyImageData}
          style={'transactional-third-image'}
        />
      </div>
    </section>
  );
};

export default ThirdSection;
